import { graphql } from "gatsby"
import React from "react"
import JobDetailContent from "../components/Jobs/JobDetailContent"
import JobDetailHeader from "../components/Jobs/JobDetailHeader"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

function JobOffer({ data }: any) {
  const {
    location,
    type,
    salary,
    salary_period,
    date,
    name,
    lang,
    header_img,
  } = data.static.frontmatter

  const content = data.static.html

  const seo = {
    metaTitle: name,
  }

  const offerSubtitle = `${location} - ${type} `

  const header_img_data = data.staticImg.nodes.find((el: any) => {
    return header_img == el.name
  })?.childrenImageSharp[0]

  return (
    <Layout navLight={true}>
      <Seo seo={seo} />
      <JobDetailHeader
        title={name}
        subtitle={offerSubtitle}
        lang={lang}
        header_img={header_img_data}
      />
      <JobDetailContent content={content} />
    </Layout>
  )
}

export default JobOffer

export const query = graphql`
  query ($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    static: markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { lang: { eq: $language } }
    ) {
      html
      frontmatter {
        name
        location
        type
        lang
        date
        salary_period
        salary
        header_img
      }
    }
    staticImg: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativePath: { glob: "assets/jobs/*" }
      }
    ) {
      nodes {
        name
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
