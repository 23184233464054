import React from "react"
import { Trans } from "react-i18next"
import { contactDetails } from "../../pages/contact"

function JobDetailContent({ content }: any) {
  return (
    <section className="relative py-16 md:py-24">
      <section className="container">
        <div className="grid grid-cols-1 lg:grid-cols-12">
          <div className="lg:col-span-10 lg:col-start-2">
            <p
              className="job-content text-justify text-slate-500 dark:text-slate-300"
              dangerouslySetInnerHTML={{ __html: content }}
            ></p>
          </div>
        </div>
        <div className="mt-6 text-center">
          <a
            href={`mailto:${contactDetails.email}`}
            className="btn btn-primary mr-2 mt-2 rounded-md"
            aria-label="Apply Button"
          >
            <i className="mdi mdi-email mr-1"></i>
            <Trans>BUTTON.apply</Trans>
          </a>
        </div>
      </section>
    </section>
  )
}

export default JobDetailContent
